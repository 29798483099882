import axios from 'axios'
import { hasIn } from 'lodash'
export default async function request(
    options = {}) {

    let { data, status } = await axios(options).catch(err => {
        if (err.response) {
            return {
                data: hasIn(err.response.data, 'response.data') ? err.response.data : [],
                status: hasIn(err.response.status, 'response.status') ? err.response.status : 499,
            }
        }
        else {
            return {
                data: {},
                status: 499
            }
        }
    })

    return { data, status };

}
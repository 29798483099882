import { useState } from 'react';

import Modal from "./components/modal"
import Form from './components/form';
// import logo from './assets/logo.svg'
function App() {
  let [isOpen, setIsOpen] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <div className="font-cairo antialiased flex flex-col min-h-screen  bg-gradient-to-r from-orange-400 to-rose-400">
      <div className='flex justify-center m-5 md:m-10'>
        {/* <img src={logo} className="h-auto md:h-20" /> */}
        <h3 className='font-extrabold text-5xl text-white'>LOGO</h3>
      </div>
      <div className="flex justify-center">
        <div className="bg-white shadow-md rounded-3xl p-6 mx-3">
          <Form openModal={openModal} />
          <Modal isOpen={isOpen} closeModal={closeModal} />
        </div>
      </div >
    </div >
  );
}

export default App;
